nav {
	z-index: 1;
}
nav button.menu {
	background: transparent no-repeat center / 60%;
	background-image: url('../img/icons/menu.svg');
	width: 2.5em;
	height: 2.5em;
	border: none;
	margin: 0.5em;
	padding: 0;
}
nav.opened button.menu {
	background-image: url('../img/icons/close.svg');
}
nav ul {
	position: absolute;
    left: 0.25em;
	display: flex;
	flex-flow: column nowrap;
	padding: 0;
	margin: 0;
	min-width: 10em;
	filter: drop-shadow(1px 1px 1px var(--color-bg-double));
}
nav li {
	padding: 0;
	display: flex;
	align-items: center;
	z-index: 2;
	position: relative;
	background-color: var(--color-surface);
	color: var(--color-text);
}
nav li:first-child {
	border-radius: var(--border-radius) var(--border-radius) 0 0;
}
nav li:last-child {
	border-radius: 0 0 var(--border-radius) var(--border-radius);
}
nav li.account {
	background-color: var(--color-fg);
	color: var(--color-surface);
}
nav li.account span {
	display: block;
}
nav li:first-child a::before {
	content: "";
	position: absolute;
    top: -0.25em;
    left: 1.15em;
    z-index: 0;
    width: 0.75em;
    height: 0.75em;
	transform: rotate(45deg);
	background-color: inherit;
	border-radius: 0.25em;
}
nav li:hover {
	color: var(--color-surface);
	background-color: var(--color-special);
}
nav li > * {
	padding: 0.75em 1em;
	flex: 1;
	white-space: nowrap;
	text-decoration: none;
	font-weight: 600;
	z-index: 1;
	position: relative;
	border-radius: inherit;
	background-color: inherit;
	color: inherit;
	margin: 0;
    font-size: 1em;
	text-align: left;
	display: flex;
    align-items: center;
    gap: 1em;
}
nav li > button {
	background-color: var(--color-bg-half);
	border-radius: inherit !important;
}
nav .avatar {
	box-shadow: 0 0 0 1px var(--color-fg) inset;
	margin: 0 0 0.5rem 0;
	border-radius: 50%;
	font-size: 4em;
}
nav .avatar > span {
	background-color: inherit;
	color: var(--color-fg);
}
nav .logout:hover,
nav li:hover .avatar > span {
	box-shadow: 0 0 0 1px var(--color-surface) inset;
	color: var(--color-surface);
}
nav .logout {
	color: var(--color-error);
}
nav .logout:hover {
	background-color: var(--color-error);
}
nav .organization {
	background: var(--color-surface) linear-gradient(var(--color-bg), var(--color-bg));
	transition: 0.3s all;
	height: 3em;
}
nav .organization.hide {
	height: 0;
	overflow: hidden;
	transition: 0.3s all;
}
nav .organization.selected {
	background-image: linear-gradient(var(--color-bg-double), var(--color-bg-double));
}
nav .organization button {
	display: flex;
	gap: 0 0.75em;
	font-size: 0.9em;
	align-items: center;
}
nav .organization button::before {
	content: "○";
	font-size: 1.5em;
	line-height: 0;
	margin-top: -0.125em;
}
nav .organization.selected button::before {
	content: "●";
}
nav .organization:hover button::before {
	color: var(--color-surface);
}
nav .organization.selected button::after {
	content: "◢";
    margin-left: auto;
	transform: rotate(315deg) translateY(0px);
    font-size: 0.75em;
	transition: all 0.2s;
}
nav .organization.selected.showing button::after {
	transform: rotate(405deg) translateY(-3px);
	transition: all 0.2s;
}
nav .organization button img {
	height: 2.5em;
	width: 2.5em;
	background-color: var(--color-surface);
	object-fit: contain;
	padding: 0.25em;
	margin: -0.25em 0;
	border-radius: 0.25em;
}
nav .organization button span {
	font-size: 0.95em;
}