form {
	display: flex;
	flex-flow: column;
	gap: 1.5em;
}
a,
input,
select,
textarea,
button {
	border: none;
	font: inherit;
	margin: 0;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
input, select, textarea {
	border-radius: var(--border-radius-child);
	box-shadow: inset 0 0 0 1px var(--color-bg-strong);
	background-color: var(--color-surface);
    padding: 0.75em;
    width: 100%;
    color: var(--color-fg);
}
input:focus, select:focus, textarea:focus {
	box-shadow: inset 0 0 0 0.075em #BCD3E9;
	background-color: #F7FBFD;
}
::placeholder { 
	color: #7B98A7;
	opacity: 1; 
}
:-ms-input-placeholder { 
	color: #7B98A7;
}
::-ms-input-placeholder { 
	color: #7B98A7;
}
input:focus::placeholder, 
select:focus::placeholder, 
textarea:focus::placeholder {
	color: #667b83;
}
textarea {
	resize: vertical;
	width: 100%;
	min-height: 8em;
	text-indent: 0em;
	padding: 0.7em 1.1em;
}
::file-selector-button,
::-webkit-file-upload-button {
	padding: 0.5em 0.6em;
	font-size: 0.9em;
	margin: 0;
	font: inherit;
}
::file-selector-button + * {
	display: block;
	text-align: center;
}
::file-selector-button,
::-webkit-file-upload-button,
button {
	display: inline-block;
	background-repeat: no-repeat;
	font-weight: 600;
	font-size: inherit;
	cursor: pointer;
	color: var(--color-fg);
}
input[type="file" i] {
	display: flex;
    flex-flow: column nowrap;
	text-indent: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    box-shadow: none;
}
input[type="file" i] span {
	display: block;
	text-align: center;
}
button span {
	font-size: 1.1em;
}
::-webkit-file-upload-button,
.button,
button[type="button"] {
	width: 100%;
	border-radius: var(--border-radius);
	background-color: transparent;
	padding: 0.75em 1em;
	box-shadow: 0 0 0 2px inset var(--color-fg);
	border: none;
	text-transform: none;
	opacity: 1;
	font-size: 1em;
	font-weight: 600;
	margin: 0;
}
.field.password {
	flex-flow: row wrap;
	justify-content: space-between;
}
.field.password label {
	width: 100%;
}
.field.password:last-of-type input {	
	width: calc(100% - 3.5em);
}
button.togglepass {
	width: 2.7em;
	height: 2.7em;
	margin: 0;
	background: url('../img/icons/eye-off.svg') no-repeat center / 50%;
	background-blend-mode: lighten;
}
button.togglepass.text {
	background-image: url('../img/icons/eye.svg');
}

fieldset {
	margin: 0;
	border: none;
	padding: 0;
}
fieldset legend {
	margin: 0 0 1em 0;
	padding: 0;
	text-align: center;
}
.fields {
	display: flex;
	flex-flow: column nowrap;
	margin: 0 0 1em 0;
	gap: 1em;
    border-radius: var(--border-radius);
	background-color: var(--color-bg-half);
	box-shadow: 0 0 0 2px var(--color-bg-double) inset;
    padding: 1.4em;
}
.fields:last-child {
	margin: 0;
}
form .field {
	display: flex;
	flex-flow: column;
    flex: 1;
}
.field.radio > span,
label {
	text-transform: uppercase;
    font-size: 0.9em;
    text-align: center;
    display: block;
    margin: 0.5em 0.1em;
    opacity: 0.6;
    font-weight: 700;
	letter-spacing: 0.5px;
}
.field.radio > span {
	text-align: left;
}
.field.radio label {
	text-transform: none;
	opacity: 1;
	font-weight: inherit;
	display: flex;
	align-items: center;
	margin: 0.75em 0 0 0;
	box-shadow: 0 0 0 0.1em var(--color-fg-50);
	padding: 0.75em 1em;
	border-radius: var(--border-radius-child);
	gap: 1em;
	cursor: pointer;
}
.field.radio label:hover {
	background-color: var(--color-surface-50);
}
.field.radio input {
	box-shadow: none;
}
.field.radio label span {
	flex: 1 0 calc(100% - 2em);
	opacity: 0.9;
}
.field.radio input:checked + span {
	font-weight: 600;
	opacity: 1;
}
.field>label {
	text-align: left;
	margin-top: 0;
}
.field p {
	font-size: 0.9em;
	margin: 0.5em 0 0 0;
}
form .field p.success {
	font-weight: 600;
	background-color: var(--color-success-background);
	color: var(--color-success);
	padding: 1em;
	border-radius: var(--border-radius);
}
form .field p.error {
	font-weight: 600;
	background-color: var(--color-error-background);
	color: var(--color-error);
	padding: 1em;
	border-radius: var(--border-radius);
}
button[type="submit"] {
    background-color: var(--color-text);
    padding: 0.75em 1.2em;
    border: none;
    border-radius: var(--border-radius);
    align-self: center;
    width: 100%;
    background-image: var(--bg-special-75);
    color: var(--color-surface);
}
.buttons button[type='button'] {
	border: none;
	margin: 1em 0 0 0;
}
button.link {
	color: var(--color-special);
	font: inherit;
	font-weight: bold;
	box-shadow: none;
	transform: none;
	transition: none; 
	margin: 0;
	padding: 0;
	background-color: transparent;
}
form:invalid button[type="submit"],
.button:disabled,
button:disabled {
	transform: translate(0, -0.1em) scale(1.02);
    filter: brightness(1.1);
    transition: all 0.1s ease-out;
    background: rgb(133 133 133 / 19%);
    color: rgb(69 69 69 / 55%);
    text-shadow: 1px 1px 0 #ffffffb5;
    box-shadow: none;
    cursor: default;
	border: none;
}
input:disabled,
textarea:disabled,
select:disabled {
	color: rgba(81, 81, 81, 0.69);
    background-color: rgb(218, 218, 218);
	text-shadow: 1px 1px #fff;
    box-shadow: inset 0 0 0 0.075em #cdcdcd;
}
.field.pin input {
	font-weight: 700;
	font-size: 1.25em;
}
form footer {
	padding: 1em 0;
}
.field.inputcheckbox {
	flex-flow: row-reverse nowrap;
	justify-content: flex-end;
	margin: 1em 0;
}
.field.inputcheckbox input {
    width: 1em;
    height: 1em;
    font-size: 1.2em;
    margin: 0 0.5em 0 0;
}
.field.inputcheckbox label {
    margin: 0;
}

.bad, button.bad {
	background-color: var(--color-error);
	color: var(--color-surface);
	border: none;
}
.good, button.good {
	background-color: var(--color-success);
	color: var(--color-surface);
	border: none;
}

.buttongroup {
	width: 100%;
	margin: 0;
	gap: 1em;
	display: flex;
}
.field.buttongroup>div {
	display: flex;
	flex-flow: wrap;
}
.buttongroup button {
	flex-flow: row;
	flex: 1;
	display: flex;
	white-space: nowrap;
	align-items: center;
	margin: 0 1em 1em 0;
}
.buttongroup.choice button {
	margin: 0em;
    box-shadow: none;
    flex: 0 1 0;
    padding: 0.5em 1em;
}
.buttongroup button img {
	height: 1.5em;
	margin: 0 0.75em 0 0;
}
.buttongroup button.selected {
	border-width: 0.2em;
	filter: contrast(2) brightness(1.5);
	box-shadow: 0 0 0 0.3em #fff;
}
.buttongroup button::before {
	content: '✔️ ';
	filter: brightness(5);
	margin: 0 0.75em 0 0;
	display: inline-block;
	font-size: 0.01em;
	opacity: 0;
	transition: 0.3s all;
}
.buttongroup button.selected::before {
	font-size: 0.8em;
	opacity: 1;
	transition: 0.3s all;
}

/*
.textarea+.field:nth-of-type(2n) {
	margin-left: 0em;
}
.field>div {
	display: flex;
	align-items: center;
}
input[type='checkbox'],
input[type='radiobutton'] {
	margin-right: 1em;
	width: 1.25em;
	height: 1.25em;
}
input:invalid,
select:invalid,
textarea:invalid {
	box-shadow: inset 0 0 0 0.075em #E57373;
    background-color: #fdf1f5;
    color: #bf2e4f;
}

input[type="file" i] {
	display: flex;
    flex-flow: column;
    padding: 0 0 0 1em;
}
button {
	margin: 0.375em 0;
	display: inline-block;
	background-repeat: no-repeat;
}
.button,
::-webkit-file-upload-button,
button {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	border-radius: 0.4em;
    color:  var(--special-color);
	font-weight: bold;
	font-size: inherit;
	cursor: pointer;
	transition: all 0.1s ease-out;
	display: inline-flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	box-shadow: 0 0.25em 1em rgba(0,0,0,0.05), 0 1em 1.5em -1.25em rgba(0,0,0,1);
	box-shadow: 0 0.01em 0em rgba(0,0,0,0.01), 0 0em 0.5em -1.25em rgba(0,0,0,1);
}
.button {
    border: 2px solid rgba(var(--special-color-values), 0.45);
    color: rgba(var(--special-color-values), 0.8);	
}
.button,
button[type="button"] {
	background-color: transparent;
	padding: 0.5em 1em;
	box-shadow:  0 0.01em 0em rgba(0,0,0,0.01), 
		0 0em 0.5em -1.25em rgba(0,0,0,1), 
		0 0 0 2px var(--color-fg);
}
button[type="submit"] {
	background-color: var(--special-bgcolor);
	padding: 0.75em 1.2em;
	border: none;
}
::-webkit-file-upload-button {
	padding: 0.5em 0.6em;
    font-size: 0.9em;
    margin: 0.5em -0.5em;
}


.button:hover,
button:hover {
	box-shadow: 0 0.25em 1em rgb(0 0 0 / 5%), 0 1em 0.6em -1em rgba(0 0 0 / 15%);
	transform: translate(0, -0.05em) scale(1.02);
	transition: all 0.1s ease-out; 
}
button[type='button']:hover {
	box-shadow: 0 0.25em 1em rgb(0 0 0 / 5%), 
	0 1em 0.6em -1em rgba(0 0 0 / 15%),
	0 0 0 2px rgba(var(--special-color-values), 0.25);
}
.button:focus,
button:focus {
	outline: none;
}
button[type='button']:active {
	box-shadow: 0 0.2em 0.5em rgba(0,0,0,0.05), 
	0 0.75em 1.1em -1.1em rgba(0,0,0,1),
	0 0 0 2px rgba(var(--special-color-values), 0.25);
}
.button:active,
button:active {
	box-shadow: 0 0.2em 0.5em rgba(0,0,0,0.05), 0 0.75em 1.1em -1.1em rgba(0,0,0,1);
    transform: translate(0, 0em) scale(1.01);
    filter: brightness(1.1);
    transition: all 0.1s ease-out;
}

button.link:hover {
	text-decoration: underline;
	box-shadow: none;
}

form:invalid button[type="submit"]:hover {
	box-shadow: none;
	transform: none;
	transition: none; 
	cursor: default;
}
form:invalid button[type="submit"],
.button:disabled,
button:disabled {
	transform: translate(0, -0.1em) scale(1.02);
    filter: brightness(1.1);
    transition: all 0.1s ease-out;
    background-color: rgb(133 133 133 / 19%);
    color: rgb(69 69 69 / 55%);
    text-shadow: 1px 1px 0 #ffffffb5;
    box-shadow: none;
    cursor: default;
	border: none;
}
.buttons {
	display: flex;
	justify-content: flex-end;
	overflow: visible;
	flex-flow: column;
}
.buttons::before {
	content: "";
}
.buttons button[type="submit"] {
	align-self: flex-end;
	flex: 1;
}
button.send {
	display: flex;
	align-items: center;
}
button.send::before {
	content: "";
	width: 1.25em;
	height: 1.25em;
	margin: 0 0.5em 0 0;
}
button.accept {
	display: flex;
	align-items: center;
}
button.accept::before {
	content: "";
	filter: brightness(10);
	width: 1.25em;
	height: 1.25em;
	margin: 0 0.5em 0 0;
}
fieldset {
	max-width: 1200px;
    border-radius: 0.5em;
    padding: 1.5em 1.4em 1.5em;
    margin: 1em auto;
    border: var(--border-width) solid var(--special-color);
    margin: 2em 0 .5em 0;
}
form:invalid fieldset {
    border: 1px solid #E57373;
}
legend {
	font-size: 1.4em;
	padding: 0 1em;
	color: var(--special-color);
	line-height: 1;
}
legend+p {
	margin-top: 0
}
form .field.textarea {
	width: 100%;
	margin-left: 0;
}
form .field.titulo,
form .field.papel,
form .field.classificacao {
	margin-left: 0;
	width: 100%;
}
.field.dataacontecimento:nth-of-type(2n),
.field.titulo:nth-of-type(2n),
.field.classificacao:nth-of-type(2n) {
	margin-left: 0;
}
.field.id_tipo {
	margin-left: 1em;
}
.files {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}
.files figure {
	margin: 0 0.5em 0.5em 0;
	padding: 0.5em;
	background-color: #e7ecf1;
	border-radius: .4em;
	display: flex;
	flex-flow: column;
	align-items: center;
}
.files img {
	border-radius: .3em;
	height: 10em;
}
.files figcaption {
	font-size: 0.9em;
	color: #5a5e63;
	text-align: center;
	margin: 0.5em 0 0 0;
}




.fields > small {
	width: 100%;
}
*/