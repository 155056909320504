:root{

	--color-bg: rgba(14, 8, 77, 0.1);
	--color-bg-half: rgba(14, 8, 77, 0.05);
	--color-bg-double: rgba(14, 8, 77, 0.2);
	--color-bg-strong: rgba(14, 8, 77, 0.5);
	
	--color-text: rgb(21, 31, 48);

	--color-fg: rgba(0, 24, 65, 1);

	--color-special: rgba(0, 212, 145, 1);

	--color-surface: rgba(251, 251, 252, 1);
	--color-surface-75: rgba(251, 251, 252, 0.75);
	--color-surface-50: rgba(251, 251, 252, 0.5);
	--color-surface-25: rgba(251, 251, 252, 0.25);

	--bg-special-25: linear-gradient(var(--color-special-25), var(--color-special-25));
	--bg-special-50: linear-gradient(var(--color-special-50), var(--color-special-50));
	--bg-special-75: linear-gradient(var(--color-special-75), var(--color-special-75));
	--bg-special: linear-gradient(var(--color-special), var(--color-special));

	--border-radius: 0.5rem;
	--border-radius-child: 0.25rem;
	--border-radius-parent: 1rem;

	--box-shadow: 1px 1px var(--color-bg-double);
	
    --color-error-values: 218, 25, 25;
    --color-error-background: rgba(var(--color-error-values), 0.1);
    --color-error: #e22d4f;

    --color-success-values: 0, 100, 60;
    --color-success-background: rgba(var(--color-success-values), 0.1);
    --color-success: rgba(var(--color-success-values), 0.8);

    --color-warn-values: 140, 130, 0;
    --color-warn-background: rgba(var(--color-warn-values), 0.15);
    --color-warn: rgba(var(--color-warn-values), 0.8);

    --color-info-values: 0, 60, 120;
    --color-info-background: rgba(var(--color-info-values), 0.1);
    --color-info: rgba(var(--color-info-values), 0.9);

	--scrollbarBG: #272b61;	
	--thumbBG: #4d599e;
	--scrollbarBorder: #272b61;
	
	--elevation-shadow1: drop-shadow(1px 1px 1px rgba(0,0,0,0.25));
	--elevation-shadow2: drop-shadow(2px 2px 2px rgba(0,0,0,0.25));
	--elevation-shadow4: drop-shadow(4px 4px 4px rgba(0,0,0,0.25));
	--elevation-shadow8: drop-shadow(8px 8px 8px rgba(0,0,0,0.25));
	--elevation-shadow16: drop-shadow(16px 16px 16px rgba(0,0,0,0.25));
}
*, *::before, *::after {
	box-sizing: border-box;
	font-family: "Exo 2", Roboto, Arial, Helvetica, sans-serif;
}
h1, h2, h3, h4, h5, h6 {
	margin: 0;
	line-height: 1.1;
}
p {
	margin: 0;
	line-height: 1.35;
}
html {
	height: 100%;
	min-width: 320px;
}
body {
	height: 100%;
	font: 18px "Exo 2", Roboto, Arial, Helvetica, sans-serif;
	font-weight: 400;
	background-color: var(--color-bg);
	color: var(--color-fg);
	margin: 0;
	padding: 0;
}
#root {
	padding: 1.25em;
	min-height: 100%;
	display: flex;
	flex-flow: column;
	align-items: stretch;

}
main {
	padding: 1.5em 0 5em 0;
}
.grecaptcha-badge {
    transform: scale(0);
}
a {
	font-weight: 600;
	color: var(--color-fg);
	text-decoration: none;
}
a:hover {
	text-decoration: underline;
}
a:visited {
}
progress::-webkit-progress-bar {
	background-color: var(--color-bg);
	border-radius: var(--border-radius);
}
progress {
	background-color: var(--color-bg);
	border-radius: var(--border-radius);
	border: none;
	height: 0.5em;
	width: 100%;
}
.card progress {
	height: 0.2em;
}
progress::-webkit-progress-value {
	/* background-color: var(--color-special) !important; */
	background-color: var(--color-special);
	border-radius: var(--border-radius);
}
progress::-moz-progress-bar {
	/* background-color: var(--color-special) !important; */
	background-color: var(--color-special);
	border-radius: var(--border-radius);
}

h1 {
	font-weight: 700;
    font-size: 1.25em;
    width: 80%;
    margin: 0.5em auto;
    text-align: center;
}
h1 span+small {
	font-weight: 400;
	font-size: 0.75em;
	display: block;
	opacity: 0.75;
}
h4 label {
	margin-bottom: 0;
}
h4 label+span {
	display: block;
	text-align: center;
}
h3 {
	font-weight: 800;
	font-size: 1.25em;
	opacity: 0.5;
}
h5 {
	margin: 0 0 -0.75em 0;
	text-align: center;
	font-size: 0.85em;
	opacity: 0.7;
}
figure {
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80%;
	height: 5.75em;
}
figure svg {
	width: 100%;
	height: 100%;
}
figure img {
	max-width: 100%;
	max-height: 100%;
	min-width: 60%;
	border-radius: inherit;
	object-fit: contain;
}
.card {
	background-color: var(--color-surface-75);
	border-radius: var(--border-radius);
	display: grid;
	gap: 0.5em;
	padding: 0.5em 0.75em;
	cursor: pointer;
}
.card:hover {
	background-color: var(--color-surface);
}
.card + .card {
	margin-top: 0.5em;
}
.card time {
	font-size: 0.9em;
}
.card .icon {
	width: 2em;
}
.card.commission {
	grid-template-areas: "a b";
	grid-template-columns: min-content auto;
}
.card.action {
	grid-template-areas: "a a a" "b c d";
	grid-template-columns: min-content auto min-content;
}
.card.action .status {
	grid-area: a;
}
.card.action .icon {
	width: 2em;
	grid-area: b;
	align-self: center;
}
.card.action p {
	grid-area: c;
	margin: 0;
	display: flex;
	align-items: center;
}
.card.action .avatar {
	grid-area: d;
}
.card.task {
	grid-template-areas: "a a a" "b c d";
	grid-template-columns: min-content auto min-content;
	grid-template-rows: min-content min-content;
    align-items: center;
}
.card.task b {
	grid-area: a;
}
.card.task .icon {
	grid-area: b;
}
.card.task time {
	grid-area: c;
}
.card.task .avatar {
	grid-area: d;
}
/* .commission figure, */
.card figure {
	grid-area: a;
	width: 2.5em;
	height: 2.5em;
}
.commission figure.symbol {
	font-size: 2.25em;
	background-color: var(--color-bg);
	border-radius: var(--border-radius-child);
	width: 3em;
	height: 3em;
}
.commission h1 {
	font-size: 1.25em;
	margin: 0.5em auto;
	text-align: center;
}
.commission header p {
	text-align: center;
	margin: 0.5em 0;
	font-size: 0.9em;
	font-weight: 400;
}
ul.participants {
	list-style: none;
	padding: 0;
	margin: 2em;
	list-style: none;
    padding: 0;
    gap: 0.75em;
    display: flex;
    justify-content: center;
	flex-flow: row wrap;
}
ul.participants li {
	display: flex;
	position: relative;;
	font-size: 2.5em;
}
ul.participants li.responsible {
	flex: 1 0 100%;
	justify-content: center;
	font-size: 3em;
}
li.responsible::after {
	content: url("../img/icons/responsible.svg");
	position: absolute;
	left: calc(0.75rem + 50%);
	bottom: 0;
	height: 1em;
}
.status {
	display: grid;
	gap: 0.25em;
	grid-template-areas: "a b" "c c";
	grid-template-columns: auto min-content;
	grid-template-rows: 1fr 0.75em;
	align-items: end;
}
.card .status {
	grid-area: b;
	gap: 0 0.25em;
	grid-template-rows: 1fr 0.5em;
}
header {
	margin: 0 0 3em 0;
}
header .status {
	margin: 2em 0.25em 2.5em 0.25em;
}
header .status p {
	font-weight: 400;
	font-size: 0.95em;
	text-align: left;
	margin: 0;
}
header h2 {
	text-transform: uppercase;
    font-size: 1em;
    text-align: center;
    display: block;
    margin: -1.5em 0 2.5em 0;
    opacity: 0.5;
    font-weight: 900;
	letter-spacing: 0.5px;
}
header h4 {
	text-align: center;
}
header p {
	text-align: center;
}
.status p {
	font-size: 1.05em;
	grid-area: a;
	margin: 0;
	line-height: 1.15;
}
.status output span {
	font-size: 1.75em;
	font-weight: 800;
}
.card output span {
	font-size: 1.5em;
}
.card .status p {
	font-weight: 700;
}
.status progress {
	grid-area: c;
}

nav {
	position: absolute;
	top: 0em;
	left: 0em;
}
.search {
	position: absolute;
	top: 0em;
	right: 0em;
	z-index: 1;
}
.search button {
	background: transparent no-repeat center / 60%;
	background-image: url('../img/icons/search.svg');
	width: 2.5em;
	height: 2.5em;
	border: none;
	margin: 0.5em;
	padding: 0;
}
#root > footer {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: var(--color-fg);
	height: 3em;
	display: flex;
	justify-content: space-evenly;
	z-index: 1;
}
#root > footer button {
	width: 3em;
	padding: 0.5em;
	margin: 0;
	border: none;
	background: var(--color-fg) no-repeat center / 60%;
}
#root > footer button.main {
	font-size: 1.25em;
	border-radius: 500px;
	margin-top: -0.25em;
	width: 2.75em;
	height: 2.75em;
	background-image: url('../img/icons/pec.svg');
	background-size: 80%;
}
#root > footer button.calendar {
	background-image: url('../img/icons/calendar.svg');
}
#root > footer button.chat {
	background-image: url('../img/icons/chat.svg');
}
.viabilities {
    display: flex;
    align-items: stretch;
    gap: 0.5em;
    flex-flow: row nowrap;
    justify-content: space-between;
	margin: 2em auto 0 auto;
	width: 75%;
    max-width: 15em;
	min-width: 13em;
}
.viability {
	display: flex;
	flex-flow: column nowrap;
    justify-content: space-between;
	gap: 0.5em;
	align-items: center;
	flex: 1;
	align-content: stretch;
}
.viability span {
	background-color: var(--color-bg);
	padding: 0.25em 0.5em;
	border-radius: var(--border-radius);
	text-transform: uppercase;
	font-size: 0.75em;
	letter-spacing: 0.5px;
	font-weight: 500;
	align-self: stretch;
	text-align: center;
}
.impact {
    display: grid;
    grid-template-areas:
        "a b"
        "a c";
    grid-gap: 0.125em 0.75em;
    gap: 0.125em 0.75em;
    grid-template-columns: 35% auto;
    align-items: center;
    background-color: var(--color-bg);
    padding: 0.7em 1.5em;
    border-radius: var(--border-radius);
    margin: 0.75em auto 2em auto;
    width: 75%;
    max-width: 15em;
    min-width: 13em;
    justify-content: space-around;
    align-content: center;
    justify-items: center;
}
.impact img { 
	grid-area: a; 
}
.impact small {
	grid-area: b;
	text-transform: uppercase;
	font-size: 0.75em;
	align-self: flex-end;
	width: 100%;
}
.impact strong { 
	grid-area: c; 
	font-size: 0.9em;
	line-height: 1;
	align-self: flex-start;
}
.expectations {
	display: grid;
	margin: 2em 0;
	grid-template-areas: "a a a" "b c d" "e f g";
	grid-template-columns: 1em auto 1em;
    align-items: center;
    justify-items: stretch;
}
.expectations::before,
.expectations::after {
	content: "";
	width: 1em;
	height: 1em;
	background-color: var(--color-bg-double);
	border-radius: 50%;
}
.expectations::before {
	margin-left: 0.125em;
	grid-area: b;
	background-color: var(--color-special);
}
.expectations::after {
	translate: -1px;
	grid-area: d;
}
.expectations.done::after {
	background-color: var(--color-special);
}
.expectations.overdue progress::-webkit-progress-bar,
.expectations.overdue progress::-webkit-progress-value {
	background-color: var(--color-error);
}
.expectations.overdue progress::-moz-progress-bar {
	background-color: var(--color-error);
}
.expectations.overdue::before {
	background-color: var(--color-error);
}
.expectations label {
	grid-area: a;
}
.expectations progress {
	grid-area: c;
	border-radius: 0;
}
.expectations progress::-webkit-progress-bar {
	border-radius: 0;
}
.expectations progress::-webkit-progress-value {
	border-radius: 0;
}
.expectations time {
	grid-area: e;
	padding: 0.25em 0.125em;
}
.expectations time + time {
	grid-area: g;
    justify-self: end;
}
.expectations b {
	grid-area: f;
	justify-self: center;
}
.task h3 {
	margin: 0 0 1em 0;
}
.task .information {
	display: flex;
    align-items: stretch;
	justify-content: space-evenly;
	text-align: center;
	background-color: var(--color-bg);
	padding: 1em;
	border-radius: var(--border-radius);
}
.information > div {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	gap: 0.5em;
	justify-content: space-between;
}
.task .information .avatar {
	font-size: 3.25em;
}
.information label {
	margin: 0;
}
.information img {
	font-size: 2em;
    width: 2.25em;
    height: 2.25em;
    border-radius: 50%;
}
.context {
	display: flex;
	overflow: auto;
	list-style: none;
	margin: 1.5em -1em;
	padding: 0 1em 0.5em 1em;
	gap: 1em;
}
.context li {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	background-color: var(--color-bg);
	padding: 0.5rem;
	border-radius: var(--border-radius);
	min-width: 15em;
	width: 60%;
}
.context li h5 {
	display: flex;
	font-size: 0.75em;
	align-items: center;
	justify-content: center;
	background-color: var(--color-fg);
	color: var(--color-surface);
	margin: -0.5rem;
	padding: 0.25em 1.5em;
	border-top-left-radius: var(--border-radius);
	border-top-right-radius: var(--border-radius);
	width: calc(100% + 1.25em);
	height: 4em;
	font-weight: 700;
	text-transform: uppercase;
}
.context li p {
	margin: auto 0;
	padding: 1em;
	font-weight: 500;
}

ul::-webkit-scrollbar {
	width: 0.75em;
}
ul {
	scrollbar-width: thin;
	scrollbar-color: var(--color-surface-50) var(--color-bg-half);
}
ul::-webkit-scrollbar-track {
	background: var(--color-bg-half);
	cursor: pointer;
}
ul::-webkit-scrollbar-thumb {
	background-color: var(--color-surface-25) ;
	border-radius: var(--border-radius);
	border: 0.125em solid var(--color-bg);
}
.myaccount .avatar {
	font-size: 8em;
}
.photo .avatar {
	font-size: 8em;
}
/*
.photo figure,
	width: 1em;
	height: 1em;
	margin: 0 auto 1rem auto;
.myaccount form figure {
	cursor: pointer;
}
.myaccount>header {
	margin: 0;
}
*/

.photo-container {
	overflow: hidden;
	width: 8em;
	height: 8em;
	border-radius: 50%;
}
.photo-container > * {
	width: 100%;
	height: 100%;
}

.avatar .loading {
	background: var(--color-bg-half) url('/images/loading.gif') no-repeat center / 50%;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 50%;
}
.avatar .edit {
	background: var(--color-surface) url('../img/icons/edit.svg') no-repeat center / 67%;
    border-radius: 50%;
    position: absolute;
    width: 0.25em;
    height: 0.25em;
    bottom: 0;
    right: 0;
    filter: invert(1) brightness(43.8) hue-rotate(90deg) saturate(2.5);
}

.avatar {
	border-radius: 50%;
	position: relative;
	width: 1em;
	height: 1em;
	font-weight: 400;
	color: var(--color-bg);
	display: flex;
	align-items: center;
	justify-content: center;
}
.avatar.pointer {
	cursor: pointer;
}
figure.avatar > * {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}
.avatar > span {
	display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.5em;
    background-color: var(--color-fg);
    border-radius: 50%;
    color: var(--color-surface-75);
}


.avatar img {
	background-color: var(--color-bg);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	color: var(--color-bg-double);
}
/*
*/
.card .avatar {
	justify-self: end;
}

.seephoto {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	overflow: auto;
	z-index: 1;
	background-color: var(--color-surface);
	display: flex;
    align-items: center;
}
.seephoto img {
	border-radius: 0.25em;
	max-width: 100%;
	max-height: 100%;
}
.seephoto.magnified img {
	max-width: unset;
	max-height: unset;
}

main.participants ul {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	gap: 2em 1em;
}
main.participants li {
	display: flex;
	justify-content: center;
}
main.participants a {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	text-decoration: none;
}
main.participants .avatar {
	font-size: 6em;
	padding: 0em;
}

main.steps ol {
	list-style: none;
	counter-reset: contador;
	padding: 0;
}
main.steps li {
	counter-increment: contador;
	margin: 0 0 0 1.85rem;
	padding: 0 0 3rem 0;
	border-left: 0.125em var(--color-special) dotted;
	display: grid;
	gap: 0.25em;
	grid-template-areas: "num title" ". description" ". date";
	grid-template-rows: min-content 1fr min-content;
	align-items: center;
}
main.steps li:last-child {
	border: none;
}
main.steps li::before {
	content: counter(contador);
	background: var(--color-special);
	font-size: 1.25em;
	width: 4rem;
	height: 4rem;
	line-height: 4rem;
	margin: 0 1em 0 -2rem;
	border-radius: 50%;
	font-weight: 800;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--color-surface);
	text-align: center;
	grid-area: num;
}
a[name] {
	grid-area: title;
	align-self: start;
}
main.steps li h3 {
	grid-area: title;
	margin: -0.1em 0 0 0;
	text-align: left;
	opacity: 0.65;
}
main.steps li p {
	grid-area: description;
	margin: 0;
}
main.steps li .date {
	grid-area: date;
	background-color: var(--color-bg);
	padding: 0.75em 1em;
	margin: 1em 0;
	border-radius: var(--border-radius);
	display: flex;
	flex-flow: column wrap;
	font-weight: 700;
	justify-self: flex-start;
}
main.steps li.done .date {
	color: var(--color-success);
	background:  var(--color-success-background) url('../img/icons/verified.svg') left calc(100% - 1em) center no-repeat;
	padding-right: 3em;
}
main.steps .date span {
	opacity: 0.6;
	margin-right: 0.5em;
	font-size: 0.9em;
}


main.help form {
	animation: aparecer 0.5s ease-out 1 forwards;
}
main.help .about {
	text-align: center;
	margin: 3em 0 0 0 ;
}
main.help h3 {
	margin: 3em 0 1em 0;
}
main.help .channels {
	margin: 3em 0;
	background-color: var(--color-info-background);
	padding: 1em;
	border-radius: var(--border-radius);
}
main.help .tickets {
	list-style: none;
	padding: 0;
	display: flex;
	gap: 1em;
	flex-flow: column nowrap;
}
main.help .tickets li {
	padding: 1em;
	border-radius: var(--border-radius);
	background-color: var(--color-bg);
	display: flex;
	flex-flow: column nowrap;
	gap: 0.25em;
}
main.help .tickets li.new {
	background-color: var(--color-warn-background);
}
main.help .tickets li.resolved {
	background-color: var(--color-success-background);
}
main.help .tickets h4 {
	margin: 0;
	font-weight: 600;
	white-space: pre-line;
}
main.help .tickets p {
	margin: 0;
}
main.help .tickets .dateCreated {
	font-size: 0.8em;
	opacity: 0.75;
}
main.help .response {
	padding: 1em 1em;
	border-radius: var(--border-radius);
	background-color: var(--color-surface-50);
	display: flex;
	flex-flow: column nowrap;
	gap: 0.25em;
	margin: 0.75em 0 0 0;
}
main.help .response.empty {
	background-color: var(--color-bg);
}
main.help .response p {
	font-weight: 600;
}
main.help li .resolved {
	display: flex;
	flex-flow: column nowrap;
	gap: 0.5em;
	margin-top: 1.5em;
	font-size: 0.9em;
}
.calendar ul {
	display: flex;
	flex-flow: column nowrap;
	gap: 2em;
	list-style: none;
	padding: 0;
	margin: 0;
}
.calendar ul h4 {
	font-weight: 800;
	font-size: 1.1em;
	opacity: 0.8;
	line-height: 1.15;
}
.calendar ul time {
	font-weight: 400;
	font-size: 0.9em;
	margin: 0.25em 0 0 0;
	display: inline-block;
}
.tag {
	border: 1px solid var(--color-bg-double);
	background-color: var(--color-surface-50);
	padding: 0.25em 0.5em ;
	font-size: 0.7em;
	border-radius: 0.5em;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin: -0.25em 0 0.25em 0;
	display: inline-block;
}
.conversations ul {
	padding: 0;
	margin: 2em 0;
	gap: 1em;
	display: flex;
	flex-flow: column nowrap;
}
.conversations li a {
	display: flex;
	flex-flow: column nowrap;
	gap: 0.25em;
}
.conversations li h4  {
	font-size: 1.1em;
	font-weight: 800;
	opacity: 0.9;
	display: flex;
	justify-content: space-between;
	gap: 1em;
	align-items: flex-start;
}
.conversations li .badge {
	background-color: var(--color-fg-50);
	width: 2.5rem;
	height: 2.5rem;
	color: var(--color-surface);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.75em;
	flex: 0 0 2.5rem;
	border-radius: 50%;
	box-shadow: 0 0 0 0.5rem var(--color-surface);
	margin-top: -0.75rem;
}
.conversations li div {
	display: grid;
	grid-template-areas: "a b" "a c";
	gap: 0.25em 1em
}
.conversations li .avatar {
	grid-area: a;
	width: 2em;
	height: 2em;
	margin-top: 0.25em;
}
.conversations li p {
	opacity: 0.75;
	grid-area: b;
}
.conversations li time {
	opacity: 0.5;
	font-size: 0.75em;
	grid-area: c;
}
.empty {
	text-align: center;
	margin: 1em;
	text-shadow: 1px 1px var(--color-bg);
	opacity: 0.75;
	font-size: 1.25em;
}
.searchDialog h3 {
	margin: 1em 0;
}
.searchDialog ul {
	margin: 1em 0;
	padding: 0;
	display: flex;
	flex-flow: column nowrap;
	gap: 0em;
	border-radius: var(--border-radius-parent);
}
.searchDialog li {
	background-color: var(--color-bg-half);
	border: 1px solid var(--color-bg-strong);
}
.searchDialog li a {
	display: flex;
	flex-flow: column nowrap;
	gap: 0.5em;
}
.searchDialog .tag {
	align-self: flex-start;
}
footer.extras {
	display: flex;
	justify-content: center	;
}

button[type].add,
button[type].edit {
	color: var(--color-surface);
	background: url('../img/icons/pencil.svg') no-repeat center, var(--bg-special-75);
	background-color: var(--color-fg);
	margin: 3em 2em;
	box-shadow: none;
	width: 3em;
	height: 3em;
	position: relative;
	border-radius: 50%;
	display: block;
}
button.edit::before {
	content: "";
}
button[type].add {
	background: url('../img/icons/plus.svg') no-repeat center, var(--bg-special-75) var(--color-fg);
}
button.add span,
button.edit span {
	position: absolute;
	top: 5em;
	color: var(--color-fg);
	font-size: 0.75em;
	left: -25%;
	width: 150%;
}
.image-view {
	background-color: var(--color-surface-25);
	padding: 1em;
	border-radius: 0.5em;
	border: 1px dashed var(--color-fg-50);
	width: 100%;
	margin-bottom: 0.5em;
}