.message {
	display: grid;
	grid-template-areas: "a b" "c b";
	grid-template-rows: 3.5em auto;
	grid-template-columns: 3em auto;
    gap: 0px 0.5em;
    margin: 0 auto 1.5em 0;
    align-items: start;
}
.message.same {
	margin-bottom: 0.25em;
}
.message.my {
	grid-template-areas: "b a" "b c";
	grid-template-columns: auto 3em;
	margin-left: auto;
	margin-right: 0;
}

.avatar + .balloon::before {
	content: "";
    width: 0.5em;
    height: 0.5em;
    position: absolute;
    background: inherit;
    top: 0;
    left: -0.15em;
    transform: skewX(30deg);
}
.my .avatar + .balloon::before {
    left: auto;
    right: -0.15em;
    transform: skewX(330deg);
}
.message.same + .message .avatar + .balloon::before {
	display: none;
}
.message.same + .message {
	grid-template-areas: "c b" "c b";
}
.message.my.same + .message {
	grid-template-areas: "b c" "b c";
	grid-template-columns: auto 3em;
}

.message.same + .message .avatar {
	display: none;
}

.message .avatar {
	grid-area: a;
    justify-self: center;
    font-size: 2.5em;
	margin-top: -0.1em;
}
.message .balloon {
	position: relative;
	grid-area: b;
	background-color: var(--color-surface);
	padding: 0.75em;
	border-radius: var(--border-radius-parent);
	border-top-left-radius: 0;
	font-size: 1.125em;
	display: flex;
	flex-flow: column nowrap;
	gap: 0.5em;
	filter: drop-shadow(1px 1px 1px #00000022);
}
.message.owner .balloon {
	background-color: var(--color-bg);
	background-color: var(--color-surface);
    background-image: linear-gradient(45deg, var(--color-bg-double), var(--color-bg-double));
}
.message.same .balloon {
	border-bottom-left-radius: 0;
}
.message.my .balloon {
	border-radius: var(--border-radius-parent);
	border-top-right-radius: 0;
	background-color: var(--color-surface);
	background-image: var(--bg-special-25);
}
.message.same.my .balloon {
	border-bottom-right-radius: 0;
}

.message .balloon p {
	margin: 0;
}
.message.owner .balloon p {
	font-weight: 500;
}
.message .balloon time {
	font-size: 0.75em;
	opacity: 0.7;
}
.message .icon {
	grid-area: c;
	justify-self: center;
	font-size: 1.25em;
	filter: 
		drop-shadow(-1px 0px 0 var(--color-bg)) 
		drop-shadow(1px 0px 0 var(--color-bg)) 
		drop-shadow(0px -1px 0 var(--color-bg)) 
		drop-shadow(0px 1px 0 var(--color-bg)) 
		drop-shadow(-1px -1px 0 var(--color-bg)) 
		drop-shadow(1px 1px 0 var(--color-bg-double)) 
		drop-shadow(1px -1px 0 var(--color-bg)) 
		drop-shadow(-1px 1px 0 var(--color-bg)) 
	;
}
.message .attachment {
	background-color: var(--color-bg);
	padding: 0.5em;
	border-radius: var(--border-radius-child);
	opacity: 1;

}
.message .attachment a {
	color: var(--color-fg);
	font-weight: 600;
	font-size: 0.9em;
	text-decoration: none;
}
.message .attachment a::before {
	content: url("../img/icons/attach_file.svg");
	filter: brightness(0);
	display: inline-block;
	vertical-align: middle;
	transform: scale(90%);
	margin: 0 0.25em 0 0;
	opacity: 0.85;
}
.messages button[type='button'] {
	margin: 2em 0;
}
.no.messages {
	margin: 2em 0;
}
.no.messages p {
	text-align: center;
	opacity: 0.5;
}